import React from 'react';
import { __ } from '../common/utils/LanguageHelper';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from '../common/theme/saas';
import Sticky from 'react-stickynode';
import { GlobalStyle, ContentWrapper } from '../containers/saas.style';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import Navbar from '../containers/Navbar';
import SEO from '../components/seo';
import { ResetCSS } from '../common/assets/css/style';
import Footer from '../containers/Footer';
import Container from '../common/components/UI/Container';
import BackgroundShape from '../containers/BackgroundShape';
import ShareExperienceForm from '../containers/ShareExperienceForm';

export default () => {
    return (
        <ThemeProvider theme={saasTheme}>
            <SEO title={__('تواصل معنا', 'Contact us')} />
            <ResetCSS />
            <GlobalStyle />
            <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                    <DrawerProvider>
                        <Navbar />
                    </DrawerProvider>
                </Sticky>
                <BackgroundShape
                    position="left"
                    className="background-shape__clients"
                />
                <Container
                    style={{
                        marginTop: 150,
                    }}
                >
                    <ShareExperienceForm />
                </Container>
                <Footer />
            </ContentWrapper>
        </ThemeProvider>
    );
};
