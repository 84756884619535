import React from 'react';
import Input from '../../common/components/Input';
import { __ } from '../../common/utils/LanguageHelper';
import Button from '../../common/components/Button';

export default function ShareExperienceForm() {
    return (
        <form className="inputWrapper">
            <Input
                inputType="text"
                label={__('الاسم', 'Name')}
                placeholder={__('ما اسمك؟', "What's your name?")}
                style={{
                    marginBottom: '25px',
                }}
            />

            <Input
                inputType="text"
                label={__('المسمى الوظيفي', 'Job title')}
                placeholder={__('المسمى الوظيفي', 'Job title')}
                style={{
                    marginBottom: '25px',
                }}
            />

            <Input
                inputType="text"
                label={__('اسم المنشأة', 'Company name')}
                placeholder={__('اسم المنشأة', 'Company name')}
                style={{
                    marginBottom: '25px',
                }}
            />

            <Input
                inputType="text"
                label={__('الموقع الإلكتروني للمنشأة', 'Company link')}
                placeholder={__('الموقع الإلكتروني للمنشأة', 'Company link')}
                style={{
                    marginBottom: '25px',
                }}
            />

            <Button
                type="submit"
                title={__('إرسال', 'Send')}
                colors="primaryWithBg"
                width="120px"
            />
        </form>
    );
}
